<template>
  <div>
    <MyWishlist></MyWishlist>
  </div>
</template>
<script>
import MyWishlist from "@/components/frontend/MyDashboard/MyWishlist.vue";
export default {
  name: "Dashboard",
  components: {
    MyWishlist,
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss">
//@import "@/assets/frontend/style/checkout.scss";
</style>
