<template>
  <section class="product_details">
    <div class="container">
      <div class="dashboard">
        <div class="container-fluid">
          <div class="row">
            <div class="profile-top-bar">
              <div class="dashboard-breadcrumb">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">Dashboard</a></li>
                  </ol>
                </nav>
                <div class="text-right" style="color: white">
                  {{ me.customer_name }}
                </div>
                <!--<div class="dropdown">
                  <button
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ me.customer_name }}
                  </button>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a class="dropdown-item" href="#"> Profile</a>
                    <a class="dropdown-item" href="#"
                      ><i class="fa fa-sign-out pull-right"></i> Log Out</a
                    >
                  </div>
                </div>-->
              </div>
            </div>
          </div>

          <div class="row row-flex">
            <Navbar></Navbar>

            <div class="col-md-9 eqal-col" style="padding-left: 5px">
              <div class="dashboard-right">
                <div class="dashboard-quotation">
                  <div class="container-fluid" id="dashboard_right">
                    <div class="col-md-9 eqal-col" style="padding-left: 5px">
                      <div class="dashboard-right">
                        <div class="dashboard-quotation">
                          <div class="container-fluid" id="dashboard_right">
                            <div class="row">
                              <div class="col">
                                <div class="business-address-list">
                                  <table
                                    id="datatable-buttons-click"
                                    class="table table-striped table-bordered"
                                  >
                                    <thead>
                                      <tr>
                                        <th>Sl</th>
                                        <th>Product name</th>
                                        <!--<th>SKU</th>-->
                                        <th>Image</th>
                                        <!--<th>Color</th>
                                        <th>Size</th>
                                        <th>Price</th>-->
                                        <!--<th>Status</th>
                                        <th>Option</th>-->
                                        <th>Option</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        role="row"
                                        class="odd"
                                        v-for="wishlist in me.customer_wishlist"
                                        :key="wishlist.id"
                                      >
                                        <td class="sorting_1">
                                          {{ wishlist.id }}
                                        </td>
                                        <td>
                                          {{ wishlist.product.name }}
                                        </td>
                                        <!--<td>ES-JMGL12565465</td>-->
                                        <td>
                                          <img :src="'https://savershall.com/suadmin/'+wishlist.product.product_images[0].image"  style=" height: 75px" :alt="logo" />
                                          <!--<img src="https://eurasiasupplies.com/application/views/module/product_image/jordana-matte-lipstick1.jpg"

                                          />-->
                                        </td>

                                        <!--<td>Shade 64</td>-->
                                        <!--<td></td>-->
                                        <!--<td>250.00৳</td>-->
                                        <!--<td>
                                          <img
                                            src="https://eurasiasupplies.com/modifyfile/images/sold_out_big.png"
                                            style="height: 30px"
                                          />
                                        </td>-->
                                        <td>
                                          <div
                                            class="btn-group btn-group-sm"
                                            role="group"
                                            aria-label="..."
                                          >
                                            <a class="btn btn-primary" href=""
                                              >Add to Cart</a
                                            ><a> </a>
                                          </div>
                                          <a> </a>
                                        </td>
                                        <td>
                                          <div
                                            class="btn-group btn-group-sm"
                                            role="group"
                                            aria-label="..."
                                          >
                                            <button class="btn btn-danger" @click="removeItem(wishlist.id)" type="button">Remove</button>
                                          </div>
                                          <a> </a>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import Navbar from "@/components/frontend/MyDashboard/Navbar.vue";
export default {
  computed: {
    ...mapGetters(["me"]),
  },
  components: {
    Navbar,
  },
  methods:{
    removeItem(wid){
      this.$store.dispatch('removeWishlist', wid);
      this.$store.dispatch('getMe');
      this.$store.dispatch('getMe');
      this.$toasted.success('Successfully removed wish list item.');
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/frontend/style/dashboard.scss";
@import "@/assets/frontend/style/details.scss";
@import "@/assets/frontend/style/bs_leftnavi.scss";
@import "@/assets/frontend/style/customize.scss";
</style>
